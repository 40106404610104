import {
    EventType,
    ReceiveMeetResponseState,
    ReceiveMeetResponseType,
    ReceivePublicMeetResponseState,
  } from 'modules/letsMeet/types/types';
  import { useEffect, useRef, useState } from 'react';
  import ResponseButton from './responseButton';
  
  import { ReactComponent as YesIcon } from 'assets/images/activity/yes.svg';
  import { ReactComponent as NoIcon } from 'assets/images/activity/no.svg';
  import MainButton from 'modules/auth/components/MainButton';
  import { ChevronRight } from 'lucide-react';
  import { CheckIcon, ForwardIcon } from 'lucide-react';
  import { toast } from 'components/ui/toast/use-toast';
  import { cn, getEventResponseNumber, getResponseTypeFromState } from 'utils/helpers';
  import TextField from 'components/textfield/TextField';
  import { Drawer } from 'vaul';
  import OverlayLoader from 'components/loader/OverlayLoader';
  import EventIcon from 'components/eventIcon/EventIcon';
  import { Button } from 'components/ui/button';
  import { ReactComponent as ArrowRight } from 'assets/images/activity/arrow-right.svg';
  import { ReactComponent as BackIcon } from 'assets/images/common/back.svg';
import UnlockResponseView from './unlockResponseView';
import ReceiveCountryCodeDropdown from './countryCodeInput';
import { Separator } from '@radix-ui/react-separator';
import { useNavigate } from 'react-router-dom';
import countryCodes from 'data/country-codes.json';
import { ReactComponent as NextIcon } from 'assets/images/common/next.svg';
import { useGuestSubscribeNotificationMutation } from 'services/slices/eventsApiSlice';
import { GuestRespondEventRequest, IEvent } from 'types';
import { ErrorIcon } from 'react-hot-toast';
import EndedResponseView from './endedResponseView';
import { Text } from 'components/text/primary/Text';
import LinkButton from 'components/buttons/main/LinkButton';
import { useSendOtpMutation, useVerifyOtpMutation } from 'modules/auth/services/verification.mutation.service';
import { IUser, OtpInputRef } from 'modules/auth/types/types';
import { isEmpty, set } from 'lodash';
import { formatPhoneNumber } from 'modules/auth/utils/stringUtils';
import ResendTimer from 'modules/auth/components/ResendTimer';
import { Controller } from 'react-hook-form';
import OTPInput from 'components/otpInput/OTPInput';
import useLocalStorage from 'hooks/useLocalStorage';
import useSessionStorage from 'hooks/useSessionStorage';
import tokenService from 'modules/auth/utils/tokenUtils';
import OneSignal from 'react-onesignal';
import { useRespondEventMutation } from 'services/event.service';
import useKeyboardHeightReset from 'hooks/useKeyboardHeightReset';
import CheckboxAsRadioButton from 'modules/auth/components/CheckBoxAsRadioButton';
import caleidoBaseApi from 'services/apiClient';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "components/ui/alert-dialog" 
import { PhoneNumber } from 'libphonenumber-js';
import { DraggableContainer } from 'components/draggableContainer';

  export const receivePublicMeetResponseContent = {
    [ReceiveMeetResponseType.ACCEPTED]: {
      emoji: '🎉',
      title: 'You are going!',
    },
    [ReceiveMeetResponseType.DECLINED]: {
      emoji: '😓',
      title: 'Sorry you can’t make it',
    },
    [ReceiveMeetResponseType.MAYBE]: {
      emoji: '🙌',
      title: 'You’re not sure',
    },
  };

  const publicPollResponseContent = {
    emoji: '🎉',
    title: "Thanks for responding"
  }
  

  // NOTE: This component is build in a single file by the original authors. Recommended to split it into multiple components following the atomic design principles. 
  // Following the same practice as refactoring may introduce bugs unintentionally.

  // TODO: Refactor this component into multiple components following atomic design principles.

  type MeetPrivateResponseProps = {
    public?: false;
    onSubmit: (response: ReceiveMeetResponseType) => Promise<void>;
    state: ReceiveMeetResponseState;
    setState: (state: ReceiveMeetResponseState) => void;
    bottomSheet?: boolean;
    setBottomSheet?: (state: boolean) => void;
    userHasRespondedBefore?: boolean
  };

  type MeetPublicResponseProps = {
    public: true;
    hideMaybe?: boolean;    
    state: ReceivePublicMeetResponseState;
    setState: (state: ReceivePublicMeetResponseState) => void;
    bottomSheet?: boolean;
    setBottomSheet?: (state: boolean) => void;
    isPoll?: boolean,
    eventId: string,
  };
  


  // TODO: put in to proper component structure
  type PublicResponseDrawerProps = {
    public: true;
    hideMaybe?: boolean;    
    state: ReceivePublicMeetResponseState;
    setState: (state: ReceivePublicMeetResponseState) => void;
    bottomSheet?: boolean;
    setBottomSheet?: (state: boolean) => void;
    isPoll?: boolean,
    eventId: string,
    drawerOpen?: boolean;
    setDrawerOpen?: (state: boolean) => void;
    event?:IEvent|null;
    response?: ReceiveMeetResponseType;
    setResponse: (response: ReceiveMeetResponseType) => void;
    name: string;
    setName: (name: string) => void;
    phoneNumber?: string;
    setPhoneNumber: (number: string) => void;
    countryCode?: string;
    setCountryCode: (code: string) => void;    
    hasRespondedBefore?: boolean;
    setHasRespondedBefore: (state: boolean) => void;
    setEventResponses?:any,
    viewState: ResponseViewState;
    setViewState: (state: ResponseViewState) => void; 
    currentView :'complete'|'otp-input'|'phone-input'|'rsvp-view'|null;
    setCurrentView : (state: 'complete'|'otp-input'|'phone-input'|'rsvp-view'|null) => void;
  }

  type ResponseViewState = 'initial' | 'secondary' | 'secondary-with-otp' |'add-reminder' |'completed';
  
  type DrawerSnap = '70px'| '320px'| '390px' |'480px'| '520px'| '580px'| '680px'| '720px'| '780px'| '800px';

  // drawer to show public response selection
  const PublicResponseDrawer: React.FC<PublicResponseDrawerProps> = (props) => {
    const [snap, setSnap] = useState<number | string | null>('70px');
    const [snapPoints, setSnapPoints] = useState<DrawerSnap[]>(['70px']);
    const [submitting, setSubmitting] = useState(false);
    const [eventResponses, setEventResponses] = useLocalStorage('cal-user-event-responses', {});
    const [eventContactIds, setEventContactIds] = useLocalStorage('cal-user-event-contact-ids', {});
    const [userAccountAvailable, setUserAccountAvailable] = useLocalStorage('cal-user-account-available', false);
    const [guestName, setGuestName] = useSessionStorage("cal-user-name", "");
    const [guestPhoneNumber, setGuestPhoneNumber] = useSessionStorage("cal-user-phone-number", "");
    const [guestCountryCode, setGuestCountryCode] = useSessionStorage("cal-user-country-code", "+1"); 
    const [sendOtp, { isLoading: isSendingOtp, isError:isErrorSending }] = useSendOtpMutation();
    const [isHostResponding, setIsHostResponding] = useSessionStorage('cal-host-responding', false);
    const [eventUserContactId, setEventUserContactId] = useSessionStorage('cal-user-event-user-contact-id', '');
    const [eventUserResponse, setEventUserResponse] = useSessionStorage('cal-user-event-user-response', '');
    const [completedEventResponses, setCompletedEventResponses] = useLocalStorage('cal-user-completed-event-responses', {}); 
    const [isAlreadyValidatedOTP, setIsAlreadyValidatedOTP] = useState(false);
    const [isDrawerComponentVisible, setIsDrawerComponentVisible] = useState(true);
    const {currentView, setCurrentView} = props;

    // const { isKeyboardOpen } = useKeyboardHeightReset();

    const isPoll = props.isPoll;

    const navigate = useNavigate();

    // show directly the secondary view if it is a poll
    useEffect(() => {
        if (props.isPoll) {
            props.setViewState('secondary');
        }
    }), []

    // TODO: fix the drawer floating issue properly when address bar is hidden in iOS devices
    useEffect(() => {
      document.body.classList.add('drawer-after');  
      return () => {
        document.body.classList.remove('drawer-after');
      };
    }, []);

    // handle response selection change
    const handleResponseChange = (_response: ReceiveMeetResponseType) => {
      if (submitting) return;
      props.setResponse(_response);
    };
  
    // hide the drawer when the response is completed
    useEffect(() => {
      if(props.viewState==='completed') props.setDrawerOpen && props.setDrawerOpen(false);
      setTimeout(() => {
        // TODO: find a proper way to set height for poll responses
        const setHeight = () => {
          let heightToUse = `${Math.round(Number(document.getElementById('response-drawer-content')?.clientHeight??'70')+10)}px`;
          if(heightToUse === '80px') heightToUse = '420px';
          setSnapPoints(prev=>[heightToUse as any,'70px']);
          setSnap(heightToUse);
        }
        setHeight();
      }, 100);
    }, [props.viewState,props.state]);

    // API call to respond to the event
    const [respondEvent, { data, error, isLoading }] = useRespondEventMutation();

    // find the event organizer 
    const eventOrganizer = props?.event?.contacts?.find(contact => contact.organizer === 1);

    // send OTP to the user
    const sendOtpToNumber = (formattedPhoneNumber:string) => {        
      if(!formattedPhoneNumber.startsWith('+1')){
        console.log("Only US numbers are allowed");
        return;
      }       
      sendOtp({ phoneNumber: `${formattedPhoneNumber}`}).unwrap().then(()=>{}).catch(()=>{});
    }

    // submit response to API
    const submitResponse = async () => {
      // prevent multiple submissions
      if (submitting) return;
  
      // validations  
      if (props.public && props.name.trim() === '') {
        toast({
          title: 'Please enter your name',
        });
        return;
      }

      if(props.phoneNumber){
        if(props.countryCode === '+1' && props.phoneNumber.length !== 10){
          toast({
            title: 'Please enter a valid phone number',
          });
          return;
        }else if(props.countryCode === '+94' && props.phoneNumber.length !== 9){
          toast({
            title: 'Please enter a valid phone number',
          });
          return;
        }
      }
  
      if (!props.response) {
        toast({
          title: 'Please select a response',
        });
        return;
      }

      // submit response
      setSubmitting(true);
  
      if (props.public) {
        // map status
        const voteStatus = props.response === ReceiveMeetResponseType.ACCEPTED ? 1 : props.response === ReceiveMeetResponseType.DECLINED ? 2 : props.response=== ReceiveMeetResponseType.MAYBE? 3: 0;

        // get previous event contact id for event if available
        const prevEventContactId  = eventContactIds[props.eventId]?? undefined;

        // data to send to API
        const data:any = {
          eventId: props.eventId,
          phoneNumber: props.phoneNumber?`${props.countryCode}${props.phoneNumber}`:'',
          eventContactId: prevEventContactId??'',
          response: voteStatus,
          responderName: props.name,
        };
        
        // send api request - Guest user event response
        try{
          const responseData =  await respondEvent(data).unwrap();      
          if(responseData){
            setUserAccountAvailable(responseData.userFlag);

            props.setState(getResponseTypeFromState(props.response));
            toast({
              title: 'Successfully responded to the event!', 
              fullscreen: true,
              backgroundColor: '#E0F4DB',
              textColor: '#060606',
              icon: <CheckIcon />,
            });

            // update local storage with success data
            setEventUserContactId(responseData.contactId);
            setEventUserResponse(voteStatus);

            // update the response data in local storage
            if(props.response) {
              setEventResponses((prev:any)=>{ return {...prev, [props.eventId]: getEventResponseNumber(props.response)}});
            }else{
              setEventResponses((prev:any)=>{ return {...prev, [props.eventId]: undefined}});  
            } 
            // save the event contact id for the event
            setEventContactIds((prev:any)=>{ return {...prev, [props.eventId]: responseData.contactId}});

            const formattedPhoneNumber = `${props.countryCode}${props.phoneNumber}`;
            
            // identify if the host has responded
            if(responseData.userFlag && eventOrganizer){
              const eventOrganizerContact = eventOrganizer?.phoneNumber??'';
              setIsHostResponding(formattedPhoneNumber === eventOrganizerContact);
            }

            // find if the number is already validated
            const foundValidated = JSON.parse(localStorage.getItem('cal-user-otp-validated-number')??'[]')?.find((vo:any)=>{
              return vo.phoneNumber==formattedPhoneNumber});
            if(!foundValidated && props.phoneNumber){
              // send OTP to the user
              sendOtpToNumber(formattedPhoneNumber);
              sessionStorage.setItem('cal-user-last-sent-otp-to-number',formattedPhoneNumber);
              props.setViewState('secondary');
              setIsAlreadyValidatedOTP(false);         
            }else{
              // user is already validated
              props.setViewState('secondary');
              sessionStorage.removeItem('cal-user-last-sent-otp-to-number');
              setIsAlreadyValidatedOTP(true);
            }

            // TODO: clear data from session storage
            // props.setName('');
            // props.setPhoneNumber('');
            // sessionStorage.removeItem('cal-user-name');
            // sessionStorage.removeItem('cal-user-phone-number');            
          }           
        }catch(err){
          toast({
            title: 'Failed to respond to the event!', 
            fullscreen: true,
            backgroundColor: '#FDE2E2',
            textColor: '#060606',
            icon: <ErrorIcon />,
          });
        } 
      }
  
      // after submission
      if (props.setBottomSheet) {
        props.setBottomSheet(false);
      }
  
      setSubmitting(false);
    };
  
    // show loader while submitting
    if (submitting) {
      return <OverlayLoader />;
    }
    
    
    // show unlock response view if user has not responded before
    if (props.hasRespondedBefore === false && props.state === ReceivePublicMeetResponseState.HIDDEN) {
        return (
        <UnlockResponseView
            onSubmit={() => 
                props.setState(ReceivePublicMeetResponseState.NOT_RESPONDED)
            }
        />)
    }else if(props.hasRespondedBefore === false && props.state === ReceivePublicMeetResponseState.ENDED){
    // show ended response view if user has not responded before and the event has ended
        return (
          <EndedResponseView
            onSubmit={() => 
                navigate('/auth/login')
            }
          />
        )
    }
   
    // top emoji and text for the response
    const updateResponseEmoji = props?.isPoll ? publicPollResponseContent.emoji : receivePublicMeetResponseContent[props.response!]?.emoji || '';
    const updateResponseText = props?.isPoll ? publicPollResponseContent.title : receivePublicMeetResponseContent[props.response!]?.title || '';  

    const isDrawerModal = props.state === ReceivePublicMeetResponseState.NOT_RESPONDED || props.state === ReceivePublicMeetResponseState.HIDDEN;

    return (
      // <Drawer.Root snapPoints={snapPoints} activeSnapPoint={snap} setActiveSnapPoint={setSnap} open={props.drawerOpen} modal={isDrawerModal} repositionInputs={false} dismissible={false} >
      isDrawerComponentVisible?
      <Drawer.Root modal={false} onClose={()=>setIsDrawerComponentVisible(false)} open={isDrawerComponentVisible} repositionInputs={false}>     
        <Drawer.Overlay className="fixed inset-0 bg-[#1F2339] touch-none hidden" /> 
        <Drawer.Portal>
          <Drawer.Content className="flex flex-col rounded-t-[10px] mt-24 h-fit fixed bottom-0 left-0 right-0 outline-none drawer-content transform translate-y-0">
            {props.viewState === 'initial' ? (
              <InitialResponseView
                hideMaybe={props.public && props.hideMaybe}
                state={props.state as ReceivePublicMeetResponseState }
                setState={props.setState as (state: ReceivePublicMeetResponseState) => void}
                bottomSheet={props.bottomSheet}
                setBottomSheet={props.setBottomSheet}
                response={props.response}
                setName={props.setName}
                name={props.name}
                phoneNumber={props.phoneNumber}
                setPhoneNumber={props.setPhoneNumber}
                countryCode={props.countryCode}
                setCountryCode={props.setCountryCode}
                handleResponseChange={handleResponseChange}
                submitResponse={submitResponse}
                submitting={submitting}
                expired={props.state === ReceivePublicMeetResponseState.ENDED}
              />
            ) : props.viewState === 'secondary' ? (
              <UserResponseView
                emoji={updateResponseEmoji}
                title={'Thank you for your RSVP!'}
                setViewState={props.setViewState}
                isPoll = {props.isPoll}
                existingUser = {userAccountAvailable}
                isPublic={true}
                eventId={props.eventId}
                state={props.state}
                setState={props.setState}
                event={props.event}
                updateActionText={updateResponseText}
                setSnap={(snap:string)=>{setSnap(prev=>snap)}}
                alreadyValidatedOTP={isAlreadyValidatedOTP}
                currentView={currentView}
                setCurrentView={setCurrentView}
              />
            ): props.viewState==='completed'?(
              <></>
            ):(
              <UpdateResponseView
                emoji={updateResponseEmoji}
                title={updateResponseText}
                isPublic={true}
                onRespondChange={() => {
                    if(props.isPoll) {
                      props.setState(ReceivePublicMeetResponseState.NOT_RESPONDED)
                    } else {
                      props.setViewState('initial')
                    }}
                }
                isPoll = {props.isPoll}
              />
            )}
            <div className='w-full h-52 bg-[#1F2339] transform translate-y-52 fixed bottom-0'></div>            
          </Drawer.Content>
        </Drawer.Portal>
      </Drawer.Root> 
      :
      <DraggableContainer onDragStart={()=>{setIsDrawerComponentVisible(true)}} longTouchThreshold={10}>
        <div className="flex flex-col bg-[#1F2339] px-7 py-5 fixed bottom-0 w-full" onClick={()=>setIsDrawerComponentVisible(true)}>
          <div className={'flex justify-center mt-4'} >
            <div className="w-28 h-2 rounded-full bg-purple-800"></div>
          </div>
        </div>
      </DraggableContainer>
    );
  };

  type ReceiveLaterPopupProps = {
    emoji: string;
    title: string;
    isPublic: boolean;
    onRespondChange: () => void;
    hideButton?: boolean;
    isPopup?: boolean;
    updateActionText?: string;
    setBottomSheet?: (state: boolean) => void;
    bottomSheet?: boolean;
    isPoll?: boolean;
    responseText?:string
  };
  

  // Initial drawer view
  // TODO: put in to proper component structure
  interface InitialResponseViewProps  {
    hideMaybe?: boolean;
    state: ReceivePublicMeetResponseState;
    setState: (state: ReceivePublicMeetResponseState) => void;
    bottomSheet?: boolean;
    setBottomSheet?: (state: boolean) => void;
    setName: (name: string) => void;
    name: string;
    response: ReceiveMeetResponseType | undefined;
    expired?: boolean;
    phoneNumber?: string;
    setPhoneNumber: (number: string) => void;
    countryCode?: string;
    setCountryCode: (code: string) => void;
    submitting: boolean;
    handleResponseChange: (response: ReceiveMeetResponseType) => void;
    submitResponse: () => void;
  }
  
  const InitialResponseView: React.FC<InitialResponseViewProps> = (props) => {
    const handleResponseSubmit = () => {        
        props.submitResponse();
    }

    const [countryCode, setCountryCode] = useSessionStorage('cal-user-country-code', '+1');
    const [phoneNumber, setPhoneNumber] = useSessionStorage('cal-user-phone-number', '');

    const handleCountryCodeChange = (code: string) => {
      setCountryCode(code);
      props.setCountryCode(code);
    }

    const handlePhoneNumberChange = (number: string) => {
      setPhoneNumber(number);
      props.setPhoneNumber(number);
    }

    return (
      <>
        <div className={cn('flex flex-col bg-[#1F2339] text-white rounded-t-xl pb-16', 'rounded-none')} id='response-drawer-content'>
          <div className={'flex justify-center mt-4'}>
            <div className="w-28 h-2 rounded-full bg-purple-800"></div>
          </div>
          <div className="mx-8 pt-5">
            <TextField
              label="What is your name?"
              required
              placeholder="Your Name"
              value={props.name}
              onChange={(e) => {
                props.setName(e.target.value);
              }}
              disabled={props.expired}
            />
          </div>
          <div className="mx-8 pt-2">
            <div className="mb-2">
              Phone number
            </div>
            <ReceiveCountryCodeDropdown
              selectedCode={countryCode??'+1'}
              phoneNumber={phoneNumber??''}
              onCodeSelect={(code) => handleCountryCodeChange(code)}
              onPhoneNumberChange={(number) => handlePhoneNumberChange(number)}
              countryCodes={countryCodes}
              disabled={props.expired}
            />
          </div>          
          <p className='my-2 text-sm text-center font-sofia-light'>signup/login for reminders</p>
          <div
            className="text-xl font-semibold text-center pt-2 mb-2 leading-8"
            onClick={() => (props?.setBottomSheet ? props.setBottomSheet(!props.bottomSheet) : null)}
          >
            {props.expired&&props.response?'You have responded as':`Are you going?`}
          </div>
  
          <div className="mb-2">
            <ResponseButton
              selected={props.response === ReceiveMeetResponseType.ACCEPTED}
              onClick={() => props.handleResponseChange(ReceiveMeetResponseType.ACCEPTED)}
              title="Yes"
              icon={<YesIcon />}
              disabled={props.expired}
            />
  
            <ResponseButton
              selected={props.response === ReceiveMeetResponseType.DECLINED}
              onClick={() => props.handleResponseChange(ReceiveMeetResponseType.DECLINED)}
              title="No"
              icon={<NoIcon />}
              disabled={props.expired}
            />
  
            {!(props.hideMaybe) ? (
              <ResponseButton
                selected={props.response === ReceiveMeetResponseType.MAYBE}
                onClick={() => props.handleResponseChange(ReceiveMeetResponseType.MAYBE)}
                title="Maybe"
                variant="secondary"
                disabled={props.expired}
              />
            ):<></>}
          </div>
          {!props.expired ? <MainButton
            type="primary"
            onClick={handleResponseSubmit}
            className="mx-8 box-border w-auto"
            icon={<ChevronRight color="#fff" />}
            loading={props.submitting}
            disabled={props.expired}
          >
            Send Response
          </MainButton>:<></>}
        </div>
      </>
    );
  };
  


  // NOTE: not clear what the logic here is, created separate components for different cases
  // TODO: put in to proper component structure
  const UpdateResponseView: React.FC<ReceiveLaterPopupProps> = (props) => {
    const storedPhoneNumber = localStorage.getItem("cal-user-phone-number") ?? "";
    const storedCountryCode = localStorage.getItem("cal-user-country-code") ?? "+1";
    
    const [phone, setPhone] = useState(storedPhoneNumber);
    const [code, setCode] = useState(storedCountryCode);
  
    const navigate = useNavigate();
    
    return (
      <div className="flex flex-col bg-[#1F2339] px-7 py-5"  id='response-drawer-content'>
        <div className={cn('flex flex-col items-center pt-9 pb-16 bg-[#1F2339] rounded-t-xl px-2', props.isPublic && 'pb-3 pt-3')}>
          <div
            className="w-28 h-2 mb-10 rounded-full bg-purple-800"
            onClick={() => (props?.setBottomSheet ? props.setBottomSheet(!props.bottomSheet) : null)}
          ></div>
          <EventIcon icon={props?.isPoll ? publicPollResponseContent.emoji : props.emoji} background="#ebdfff" size="medium" />
          <div className={cn('font-serif text-2xl text-center tracking-tighter text-white mt-2', props.isPublic && props.isPopup && 'font-sans tracking-normal text-xl font-semibold')}>
            {props.title}
          </div>
          {!props.hideButton && (
            <Button
              variant="link"
              className="text-link hover:text-link font-medium !no-underline flex items-center gap-2 py-0"
              onClick={props.onRespondChange}
            >
              <BackIcon />
              {props?.isPoll ? 'Update my Time(s)' : props.updateActionText ?? 'Update my response'}

            </Button>
          )}
        </div>

        <Separator className="bg-white/20 h-[0.8px] mb-6" />
        <div className="flex flex-col items-center">

        <div className="text-xl font-semibold text-white mb-2 text-left w-full max-w-md">
          Want text reminders?
        </div>


        <div className="text-white mb-2 text-left w-full max-w-md">
          Enter phone number
        </div>

        <ReceiveCountryCodeDropdown
          selectedCode={code}
          phoneNumber={phone}
          onCodeSelect={(code) => setCode(code)}
          onPhoneNumberChange={(number) => setPhone(number)}
          className="mb-2 mt-2 w-full max-w-md"
          countryCodes={countryCodes}
        />

        <div className="w-full max-w-md text-left">
          <Button
            variant="ghost"
            className="w-full text-left bg-transparent hover:bg-transparent text-link hover:text-link flex justify-start items-center px-0"
            onClick={() => {
              if (!phone || phone.length < 10)
                return toast({
                  title: 'Please enter a valid phone number',
                });

              navigate({
                pathname: '/auth/create',
                search: new URLSearchParams({ code, phone }).toString(),
              });
            }}
          >
            Sign up and get text reminders
            <NextIcon className="ml-1" />
          </Button>
        </div>
      </div>
    </div>
    );
  };
  


  // Response view drawer
  // TODO: put in to proper component structure
  type UserResponseViewProps = {
    emoji: string;
    title: string;
    hideButton?: boolean;
    isPopup?: boolean;
    updateActionText?: string;
    setBottomSheet?: (state: boolean) => void;
    bottomSheet?: boolean;
    isPoll?: boolean;
    existingUser?: boolean;
    isPublic?: boolean;
    setViewState: (state: ResponseViewState) => void;
    eventId: string;
    state?: ReceivePublicMeetResponseState;
    setState?: (state: ReceivePublicMeetResponseState) => void;
    hasRespondedBefore?:boolean;
    setHasRespondedBefore?: (state: boolean) => void;
    event?:IEvent|null;
    setSnap?: (snap: string) => void;
    alreadyValidatedOTP?:boolean;
    currentView: 'complete'|'otp-input'|'phone-input'|'rsvp-view'|null;
    setCurrentView: (view: 'complete'|'otp-input'|'phone-input'|'rsvp-view'|null) => void;
    
  };
  
  // drawer shown after user responds to the event 
  const UserResponseView: React.FC<UserResponseViewProps> = (props) => {
  
    const navigate = useNavigate();
    const [showCreateReminder, setShowCreateReminder] = useState(false);
    const [inOtpVerification, setInOtpVerification] = useState(false);

    const [name, setName] = useSessionStorage('cal-user-name', '');
    const [phoneNumber, setPhoneNumber] = useSessionStorage('cal-user-phone-number', '');
    const [countryCode, setCountryCode] = useSessionStorage('cal-user-country-code', '+1');

    const [checked, setChecked] = useState(false);
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [otp, setOtp] = useState<string>('');
    const [tempTokens,setTempTokens] = useSessionStorage('cal-user-temp-tokens', {});
    const [eventContactIds, setEventContactIds] = useLocalStorage('cal-user-event-contact-ids', {});
    const [onboardingUsername, setOnboardingUsername] = useLocalStorage('cal-user-onboarding-username', '');

    const [userAccountAvailable, setUserAccountAvailable] = useLocalStorage('cal-user-account-available', false);
    
    const [isHostResponding, setIsHostResponding] = useSessionStorage('cal-host-responding', false);
    const [eventUserContactId, setEventUserContactId] = useSessionStorage('cal-user-event-user-contact-id', '');
    const [eventUserResponse, setEventUserResponse] = useSessionStorage('cal-user-event-user-response', ''); 

    const {currentView, setCurrentView} = props;
    const [completedEventResponses, setCompletedEventResponses] = useLocalStorage('cal-user-completed-event-responses', {});
    const [validatedOtps,setValidaedOtps] = useLocalStorage('cal-user-otp-validated-number',[])

    const otpSentRef = useRef<boolean>(false);

    const [errors, setErrors] = useState({
      termsAccepted: '',
      phoneNumber: '',
      otp: '',
    });

    const [subscribeToEvent, { data, error, isLoading }] = useGuestSubscribeNotificationMutation();
    const [sendOtp, { isLoading: isSendingOtp, isError:isErrorSending }] = useSendOtpMutation();
    const [verifyOtp, { isLoading: isVerifyingOtp, isError:isErrorVerifying }] =useVerifyOtpMutation();

    const [smsAccepted, setSmsAccepted] = useState<boolean>(false);
    const [conformDialogVisible, setConformDialogVisible] = useState<boolean>(false);
    const promptSmsStatusRef = useRef<boolean|undefined>(undefined);
    const [isResponseLoading, setIsResponseLoading] = useState(false);

    const otpInputRef = useRef<OtpInputRef>(null);

    // API call to respond to the event
    const [respondEvent, { data:eventResponseData, error:eventResponseError, isLoading:eventResponseIsLoading }] = useRespondEventMutation();
        
    // find the event organizer 
    const eventOrganizer = props?.event?.contacts?.find(contact => contact.organizer === 1);

    // send OTP when component mounts
    useEffect(() => {

      // check if the event is already responded and completed
      if(completedEventResponses[props.eventId]){
        setCurrentView('complete');
        return;
      }

      const formattedPhoneNumber = `${countryCode==''?'+1':countryCode}${phoneNumber}`;
      if(props.isPoll){
        // poll event
        if(phoneNumber){
          // phone number is available
          (async ()=>{
            setIsResponseLoading(true);
            const isUserExists = await checkContactExists(formattedPhoneNumber);
            if(isUserExists){
              // available user
              setUserAccountAvailable(true);
            }else{
              // new user
            }
            if(props.alreadyValidatedOTP){
              setCurrentView('complete');
            }else{
              setCurrentView('otp-input');
            }
            setIsResponseLoading(false);
          })();
        }else{
          // phone number is not available
          setCurrentView('rsvp-view');    
        }
      }else{    
        if(eventUserResponse == 2){
          // respond no
          if(phoneNumber){
            // phone number is available
            (async ()=>{
              setIsResponseLoading(true);
              const isUserExists = await checkContactExists(formattedPhoneNumber);
              if(isUserExists){
                // available user
                setUserAccountAvailable(true);
              }else{
                // new user
              }
              if(props.alreadyValidatedOTP){
                setCurrentView('complete');
              }else{
                setCurrentView('otp-input');
              }
              setIsResponseLoading(false);
            })();
          }else{        
            setCurrentView('rsvp-view');
          }
        }else{
          // respond yes or maybe
          if(phoneNumber){
            // phone number is available
            if(props.alreadyValidatedOTP){
              setCurrentView('complete');
            }else{
              setCurrentView('otp-input');
            }
          }else{
            setCurrentView('rsvp-view');          
          }
        }
      }
    }, []);


    // OTP sent status reset on number change
    useEffect(() => {
      if(phoneNumber){
        otpSentRef.current = false;
      }
    },[phoneNumber, countryCode]);


    // submit poll response to API
    const checkContactExists = async (number:string) => {  
      try{
        return await caleidoBaseApi.get(`/users/registered-number/${number}`).then((response) => {
          return response?.data?.data?.isUser? true: false;
        });
      }catch(e:any){
        return false;
      }
    }   
    

    // OTP submit handler (phone number is given and OTP is sent)
    const onSubmitOtp = () => {
      // validate OTP data
      const validatedErrors:any = {};

      if(!otp || otp.length < 6) {
        validatedErrors.otp = 'Please enter a valid OTP';
      }

      if(!userAccountAvailable && !checked) {
        validatedErrors.termsAccepted = 'Please accept the terms and conditions';
      }
      
      if(Object.keys(validatedErrors).length > 0) {
        setErrors(validatedErrors);
        return;
      }

      // verify otp sent to phone number
      const  formattedPhoneNumber = `${countryCode==''?'+1':countryCode}${phoneNumber}`;
      verifyOtp({to: `${formattedPhoneNumber}`, otp}).unwrap().then((otpData)=>{
        
        // OTP validation success
        if (otpData) {

          // save the validated otp
          const prevValidatedOtps = JSON.parse(localStorage.getItem('cal-user-otp-validated-number')??'[]');
          prevValidatedOtps.push({phoneNumber: formattedPhoneNumber, time: new Date()});
          localStorage.setItem('cal-user-otp-validated-number', JSON.stringify(prevValidatedOtps));

          // set tokens to session storage
          setTempTokens(otpData.tokens);
          
          // get user data from otp response
          const user: IUser = otpData.user;

          if(userAccountAvailable && isHostResponding){
            // event host is responding
            // log the user in background
            tokenService.setTokens(otpData.tokens as any);
            // navigate to host view
            window.location.href=`/lets-meet/view/${props.eventId}`;
          }else if(userAccountAvailable){
            // the existing user is just an invitee
            setCompletedEventResponses((prev:any)=>{ return {...prev, [props.eventId]: true}});
            setCurrentView('complete');
          }else{            
            // the existing user is just an invitee
            setCompletedEventResponses((prev:any)=>{ return {...prev, [props.eventId]: true}});
            setCurrentView('complete');            
          }
                  
          // get previous event contact id for event if available
          const prevEventContactId  = eventContactIds[props.eventId]?? undefined;

          // after otp verification, subscribe to event
          const data = {
            eventId: props.eventId,
            contactId: prevEventContactId,
            phoneNumber: `${formattedPhoneNumber}`,            
          }

          // link user to created contact entry of the event
          subscribeToEvent(data).unwrap().then(()=>{ 
            // subscribe to event reminders
            setIsSubscribed(true);            
          }).catch(()=>{        
            // failed to subscribe to event reminders            
          }).finally(()=>{
            setErrors({termsAccepted: '',phoneNumber: '', otp: ''});
          });

          // add user account to OneSignal notifications
          if(user && user.id){
            (async ()=>{
              // OneSignal - Allow Request Prompt
              OneSignal.logout()
              await OneSignal.Notifications.requestPermission();    
              // OneSignal - Passing the User Details for OneSignal
              OneSignal.login(user?.id);
              OneSignal.User.addSms(user?.phoneNumber);
            })();
          }

          // set the user name to local storage     
          setOnboardingUsername(name);
        }
        
      }).catch(()=>{
        toast({
          title: 'Failed to verify OTP!',
          backgroundColor: '#FDE2E2',
          textColor: '#060606',
          icon: <ErrorIcon />,
        })
      });
    }
    
    // send otp to phone number
    const onSubmitPhoneNumber = () => {
      const validatedErrors:any = {};
      otpInputRef.current&&otpInputRef.current.reset();
      if(!checked) {
        validatedErrors.termsAccepted = 'Please accept the terms and conditions';
      }
      if(!phoneNumber || phoneNumber.length < 8) {
        validatedErrors.phoneNumber = 'Please enter a valid phone number';
      }
      if(Object.keys(validatedErrors).length > 0) {
        setErrors(validatedErrors);
        return;
      }
      // show prompt if sms is not accepted 
      const promptSmsStatus  = smsAccepted ? true : promptSmsStatusRef.current;
      if(promptSmsStatus === undefined){
        setConformDialogVisible(true);
        return;
      }
      const  formattedPhoneNumber = `${countryCode==''?'+1':countryCode}${phoneNumber}`;

      // check if the otp is already validated
      const foundValidated = JSON.parse(localStorage.getItem('cal-user-otp-validated-number')??'[]')?.find((vo:any)=>vo.phoneNumber==formattedPhoneNumber);

      if(foundValidated){
        // OTP already validated
        setCurrentView('complete');
        return;
      }else{
        sendOtp({ phoneNumber: `${formattedPhoneNumber}`, smsNotifications:promptSmsStatus }).unwrap().then(()=>{
          setInOtpVerification(true);
          toast({
            title: 'OTP sent successfully!',
            backgroundColor: '#E0F4DB',
            textColor: '#060606',
            icon: <CheckIcon />,
          })
        }).catch(()=>{
          toast({
            title: 'Failed to send OTP!',
            backgroundColor: '#FDE2E2',
            textColor: '#060606',
            icon: <ErrorIcon />,
          })
        });
      }
    }

    // send otp to phone number
    const onSubmitPhoneNumberNoTerms = () => {
      const validatedErrors:any = {};      
      if(!phoneNumber || phoneNumber.length < 8) {
        validatedErrors.phoneNumber = 'Please enter a valid phone number';
      }
      if(Object.keys(validatedErrors).length > 0) {
        setErrors(validatedErrors);
        return;
      }      
      
      // identify the user existence
      const  formattedPhoneNumber = `${countryCode==''?'+1':countryCode}${phoneNumber}`;
      (async ()=>{
        setIsResponseLoading(true);
        const isUserExists = await checkContactExists(formattedPhoneNumber);
        if(isUserExists){
          // available user
          setUserAccountAvailable(true);
          // identify if the host has responded
          if(eventOrganizer){
            const eventOrganizerContact = eventOrganizer?.phoneNumber??'';
            setIsHostResponding(formattedPhoneNumber === eventOrganizerContact);
          }
        }else{
          // new user
        }

        // check if formatted number is in validated otp list
        const foundValidated = JSON.parse(localStorage.getItem('cal-user-otp-validated-number')??'[]')?.find((vo:any)=>vo.phoneNumber==formattedPhoneNumber);

        if(!foundValidated){          
          // send OTP to the user
          sendOtpToNumber(formattedPhoneNumber);
          setCurrentView('otp-input');
          setIsResponseLoading(false);
          sessionStorage.setItem('cal-user-last-sent-otp-to-number',formattedPhoneNumber);
        }else{
          // OTP already validated
          setCurrentView('complete');
          setIsResponseLoading(false);
          sessionStorage.removeItem('cal-user-last-sent-otp-to-number');
        }
      })();   
    }

    // send otp to phone number
    const sendOtpToNumber = (formattedPhoneNumber:string) => {
      if(otpSentRef.current) return;
      // only allow US numbers
      if(!formattedPhoneNumber.startsWith('+1')){
        console.log("Only US numbers are allowed");
        return;
      }        
      sendOtp({ phoneNumber: `${formattedPhoneNumber}`}).unwrap().then(()=>{
        setInOtpVerification(true);
        toast({
          title: 'OTP sent successfully!',
          backgroundColor: '#E0F4DB',
          textColor: '#060606',
          icon: <CheckIcon />,
        })
      }).catch(()=>{
        toast({
          title: 'Failed to send OTP!',
          backgroundColor: '#FDE2E2',
          textColor: '#060606',
          icon: <ErrorIcon />,
        })
      });
    }

    // handle when phone number input change
    const handlePhoneChange = (value:string) => {
      setPhoneNumber(value);
      value.length >= 10 && setErrors(prev=>({...prev,phoneNumber:''}));
    }

    // handle guest user sign up
    const handleGuestUserSignUp = () => {
      // save auth token and refresh token
      if(tempTokens) tokenService.setTokens(tempTokens);
      window.location.href = '/profile/onboarding';
    }

    if(isLoading || isResponseLoading) return <OverlayLoader />;

    return (
      <div className="flex flex-col bg-[#1F2339] px-7 py-5 pb-16"  id='response-drawer-content'>
        <div className={'flex justify-center mt-4'}>
          <div className="w-28 h-2 rounded-full bg-purple-800"></div>
        </div>
        <div className={cn('flex flex-col items-center pt-9 pb-4 bg-[#1F2339] rounded-t-xl px-2', props.isPublic && 'pb-3 pt-3')}>
          {/* OTP sent automatically, waiting for user to enter OTP */}
          {currentView =='otp-input' ?(
              <div className='mt-2'>
                <div className="flex flex-col w-full gap-2">
                  <div className="flex flex-col gap-2">
                    <Text size="small" className="text-base">Enter the 6 digit code sent to<span className="ml-0.5 font-extralight text-secondary text-sm"> 
                        {formatPhoneNumber(phoneNumber)}</span>
                    </Text>                  
                    <div className='space-y-2'>
                      <OTPInput ref={otpInputRef} length={6} onChange={(otp) => setOtp(otp)} />
                      {errors.otp && ( <Text size="extra-small" type="error"> {errors.otp} </Text>)}
                    </div>
                    <ResendTimer initialTimer={30} onResend={onSubmitPhoneNumberNoTerms} loading={isSendingOtp} />
                  </div>
                </div>
                {!userAccountAvailable?(<>
                  <div className='space-y-0 mb-2'>
                    <div className="inline-flex items-center w-full mt-3 mb-0">                    
                      <CheckboxAsRadioButton
                        className="mr-2"
                        onChange={(e) => {setChecked(prev=>e.target.checked)}}
                        checked={checked}
                      /> 
                      <Text size="extra-small" className="text-sm">I agree to the</Text>
                      <LinkButton className="ml-1" to="/terms" target='_blank'>
                        <Text size="extra-small" className="font-semibold cursor-pointer text-sm"> Terms and Conditions</Text>
                      </LinkButton>
                    </div>
                    {errors.termsAccepted && (
                      <Text size="extra-small" type="error"> {errors.termsAccepted} </Text>
                    )}
                  </div>
                </>):(<></>)}
                <div className="w-full max-w-md text-left mt-4">
                  <MainButton 
                  type="primary"
                  onClick={onSubmitOtp}
                  className='justify-center w-full'
                  loading={isVerifyingOtp}
                >
                  <Text size="small" className="text-base font-sofia-medium">Submit</Text>
                  </MainButton>                  
                </div>
              </div>
          ): currentView=='phone-input'?(
            <div className="flex flex-col items-left">
              {/* otp verification phone number input view */}
              <div className='mt-2'>
                <div className="text-white mb-2 text-left text-base font-sofia-normal w-full max-w-md">Enter phone number</div>
                <ReceiveCountryCodeDropdown
                  selectedCode={countryCode}
                  phoneNumber={phoneNumber}
                  onCodeSelect={(code) => setCountryCode(code)}
                  onPhoneNumberChange={handlePhoneChange}
                  className="mb-2 mt-2 w-full max-w-md"
                  countryCodes={countryCodes}
                  />
                <div className='min-h-2'>
                  {errors.phoneNumber && (
                    <Text size="extra-small" type="error"> {errors.phoneNumber} </Text>
                  )}
                </div>
                <div className='space-y-0 mb-2'>
                  <div className="inline-flex items-center w-full mt-3 mb-0">                    
                    <CheckboxAsRadioButton
                      className="mr-2"
                      onChange={(e) => {setChecked(prev=>e.target.checked)}}
                      checked={checked}
                    /> 
                    <Text size="extra-small" className="text-sm">I agree to the</Text>
                    <LinkButton className="ml-1" to="/terms" target='_blank'>
                      <Text size="extra-small" className="font-semibold cursor-pointer text-sm"> Terms and Conditions</Text>
                    </LinkButton>
                  </div>
                  {errors.termsAccepted && (
                    <Text size="extra-small" type="error"> {errors.termsAccepted} </Text>
                  )}
                </div>

                <div className="flex flex-col w-full gap-2 mb-4">
                  <div className="inline-flex items-center w-full ml-0.5">                
                    <CheckboxAsRadioButton
                      className="mr-2"
                      onChange={(e) => setSmsAccepted(e.target.checked)}
                      checked={smsAccepted}
                    />                
                    <Text size="extra-small" className="text-xs">
                    I agree to receive event notification SMS messages from Caleido. Message frequency may vary. Reply STOP to unsubscribe or use app settings to opt-out.
                    </Text>                
                  </div>             
                </div>

                <div className="w-full max-w-md text-left mt-4 pb-4">
                  <MainButton 
                    type="primary"
                    onClick={onSubmitPhoneNumber}
                    className='justify-center w-full'
                    loading={isSendingOtp}
                  >
                    <Text size="small" className="text-base font-sofia-medium">Submit</Text>
                  </MainButton>                  
                </div>

                <AlertDialog open={conformDialogVisible}>      
                  <AlertDialogContent className='bg-slate-900 py-16 outline-none border-none'>
                    <AlertDialogHeader>
                      <AlertDialogTitle></AlertDialogTitle>
                      <AlertDialogDescription className='text-white'>
                        Continue without SMS notifications?
                      </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter className=''>
                      <div className='flex justify-center space-x-0'>
                        <ResponseButton
                          selected={false}
                          onClick={() =>{
                            setConformDialogVisible(false);
                            promptSmsStatusRef.current = false;
                            onSubmitPhoneNumber();
                          }}
                          title="Yes"
                          icon={<YesIcon />}
                          className='w-32 py-2 mx-2'
                          />
                        <ResponseButton
                          selected={false}
                          onClick={() =>{
                            setConformDialogVisible(false);
                          }}
                          title="No"
                          icon={<NoIcon />}
                          className='w-32 py-2'
                        />              
                      </div>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialog>
              </div>
            </div>
          ): currentView=='rsvp-view'?(<>
              <EventIcon icon={props?.isPoll ? publicPollResponseContent.emoji : props.emoji} background="#ebdfff" size="medium" />
              <div className={cn('font-serif text-2xl text-center tracking-tighter text-white mt-2', props.isPublic && props.isPopup && 'font-sans tracking-normal text-xl font-semibold')}>{props.isPoll?'Thanks for responding!':'Thank you for your RSVP!'}</div>
              <p className="text-md text-center font-normal text-white w-full max-w-md mt-2">{eventUserResponse==2?'Sign up for Caleido?':'Subscribe to reminders?'} </p>
              <div className="text-white mb-2 text-left text-base font-sofia-normal w-full max-w-md mt-3">Enter phone number</div>
              <ReceiveCountryCodeDropdown
                selectedCode={countryCode}
                phoneNumber={phoneNumber}
                onCodeSelect={(code) => setCountryCode(code)}
                onPhoneNumberChange={handlePhoneChange}
                className="mb-2 mt-2 w-full max-w-md"
                countryCodes={countryCodes}
                />
              <div className='min-h-1 w-full'>
                {errors.phoneNumber && (
                  <Text size="extra-small" type="error"> {errors.phoneNumber} </Text>
                )}
              </div>
              {eventUserResponse!=2?<p className='my-2 text-sm text-center font-sofia-light text-white'>signup/login for reminders</p>:<></>}
              <div className="w-full max-w-md text-left mt-3 pb-4">
                <MainButton 
                  type="primary"
                  onClick={onSubmitPhoneNumberNoTerms}
                  className='justify-center w-full'
                  loading={isSendingOtp}
                >
                  <Text size="small" className="text-base font-sofia-medium">Submit</Text>
                </MainButton>                  
              </div>
          </>): currentView=='complete'?(<>            
              <EventIcon icon={props?.isPoll ? publicPollResponseContent.emoji : props.emoji} background="#ebdfff" size="medium" />
              <div className={cn('font-serif text-2xl text-center tracking-tighter text-white mt-2', props.isPublic && props.isPopup && 'font-sans tracking-normal text-xl font-semibold')}>{props.updateActionText}</div>
              {!props.hideButton && (
                <Button variant="link" className="text-link hover:text-link font-medium !no-underline flex items-center gap-2 py-0"
                  onClick={()=>{
                    if(props?.isPoll){
                      props.setState&&props.setState(ReceivePublicMeetResponseState.NOT_RESPONDED);
                    } else{
                      props.setViewState('initial');
                      props.setSnap && props.setSnap('580px');
                    }
                  }}
                ><BackIcon /> {props?.isPoll ? 'Update my response' : 'Update my response'}</Button>)}
          </>):<></>}
        </div>        
    </div>
    );
  };



  // NOTE: Add reminder separate drawer, note used in the current implementation
  // TODO: put in to proper component structure
  type UserAddReminderViewProps = {
    emoji: string;
    title: string;
    hideButton?: boolean;
    isPopup?: boolean;
    updateActionText?: string;
    setBottomSheet?: (state: boolean) => void;
    bottomSheet?: boolean;
    isPoll?: boolean;
    existingUser?: boolean;
    isPublic?: boolean;
    setViewState: (state: ResponseViewState) => void;
  };
  
  const UserAddReminderView: React.FC<UserAddReminderViewProps> = (props) => {
    const storedPhoneNumber = localStorage.getItem("cal-user-phone-number") ?? "";
    const storedCountryCode = localStorage.getItem("cal-user-country-code") ?? "+1";
    
    const [phone, setPhone] = useState(storedPhoneNumber);
    const [code, setCode] = useState(storedCountryCode);
  
    const navigate = useNavigate();
    
    return (    
      <div className="flex flex-col bg-[#1F2339] px-7 py-5">
        <div className={cn('flex flex-col items-center pt-9 pb-4 bg-[#1F2339] rounded-t-xl px-2', props.isPublic && 'pb-3 pt-3')}>
          <div
            className="w-28 h-2 mb-10 rounded-full bg-purple-800"
            onClick={() => (props?.setBottomSheet ? props.setBottomSheet(!props.bottomSheet) : null)}
          ></div>           
        </div>

        <div>
          <div className="text-white mb-2 text-left w-full max-w-md">
            Enter phone number
          </div>

          <ReceiveCountryCodeDropdown
            selectedCode={code}
            phoneNumber={phone}
            onCodeSelect={(code) => setCode(code)}
            onPhoneNumberChange={(number) => setPhone(number)}
            className="mb-2 mt-2 w-full max-w-md"
            countryCodes={countryCodes}
          />

          <div className='flex flex-col'>
            <Button variant={"secondary"} className="w-full max-w-md text-white rounded-xl" onClick={()=>{alert("submitter")}}>Submit</Button>
            <Button variant={"ghost"} className="w-full max-w-md text-white rounded-xl" onClick={()=>{props.setViewState('completed')}}>Cancel</Button>
          </div>
        </div>         
      </div>
    );
  };
  


  export default PublicResponseDrawer;
