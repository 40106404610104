import caleidoBaseApi from 'services/apiClient';
import { Drawer } from 'vaul';
import { Separator } from 'components/ui/separator';
import dayjs from 'dayjs';
import { ChevronRight } from 'lucide-react';
import MainButton from 'modules/auth/components/MainButton';
import CommentList from 'modules/letsMeet/components/comments/commentList';
import InviteList from 'modules/letsMeet/components/inviteList/inviteList';
import { MeetInfo } from 'modules/letsMeet/components/meetInfo/meetInfo';
import MeetResponseAfter from 'modules/letsMeet/components/meetResponse/meetResponseAfter';
import PollResults from 'modules/letsMeet/components/meetResponse/pollResults';
import PollTimeSelector from 'modules/letsMeet/components/meetResponse/pollTimeSelector';
import { EventTitle } from 'modules/letsMeet/components/receive/eventTitle';
import ReceiveHeader from 'modules/letsMeet/components/receive/receiveHeader';
import ReceiveNavigationBar from 'modules/letsMeet/components/receive/receiveTopBar';
import {
  EventType,
  ReceiveMeetResponseState,
  ReceiveMeetResponseType,
} from 'modules/letsMeet/types/types';
import React, { useEffect, useRef, useState } from 'react';
import {
  useAddEventCommentMutation,
  useRespondEventMutation,
  useUpdateEventMutation,
} from 'services/event.service';
import { IContact, IEvent, IPollData } from 'types/event';
import { useSendNotificationMutation, useStopNotificationMutation } from 'services/notification.service';
import { NotificationType } from 'modules/letsMeet/utils/enum';
import { useAppSelector } from 'hooks/useAppSelector';
import { useNavigate } from 'react-router-dom';
import EndedResponseView from 'modules/letsMeet/components/meetResponse/endedResponseView';
import { DraggableContainer } from 'components/draggableContainer';

// #endregion

interface ReceiveMeetNowProps {
  event?: IEvent;
  refetch?: () => void;
}

const ReceiveMeetNow = (receiveProps: ReceiveMeetNowProps) => {
  const [state, setState] = useState(ReceiveMeetResponseState.NOT_RESPONDED);
  const [hasVoted, setHasVoted] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [respondEvent] = useRespondEventMutation();

  // const [respondEvent, { data, error, isLoading }] = useRespondEventMutation();
  const [addCommentMutation] = useAddEventCommentMutation();
  const [pollData, setPollData] = useState<IPollData | undefined>(
    receiveProps?.event?.pollData,
  );
  const [userId, setUserId] = useState<string | null>(null);
  const [userDisplayName, setUserDisplayName] = useState<string | null>(null);
  const [userPhoneNumber, setUserPhoneNumber] = useState<string | null>(null);
  const [updateEvent, { data, error, isLoading }] = useUpdateEventMutation();
  const inviteeContacts =
    receiveProps.event?.contacts
      ?.filter((contact) => contact.status === 1 && contact.organizer !== 1)
      .map((contact) => contact?.name) ?? [];

  const [sendNotification] = useSendNotificationMutation()
  const [stopNotification] = useStopNotificationMutation()
  const [selectedTimes, setSelectedTimes] = useState<string[]>([]);
  const user = useAppSelector((state) => state.auth.user);

  const [isDrawerComponentVisible, setIsDrawerComponentVisible] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await caleidoBaseApi.get('/auth/whoami');
        const data = response.data;
        const id = data.id;
        const name = data.name;
        const phoneNumber = data.phoneNumber;
        setUserId(id);
        setUserDisplayName(name);
        setUserPhoneNumber(phoneNumber);
      } catch (error) {
        console.error('Error fetching user details', error);
      }
    };

    fetchUserDetails();
  }, []);

  const handleTimeSelectionChange = (selectedTimes: string[]) => {
    if (!userId) return;
    if (!pollData) return;

    const newPollData: IPollData = {
      checkedTimes: pollData.checkedTimes.map((timeSlot) => ({
        time: timeSlot.time,
        voters: selectedTimes.includes(timeSlot.time)
        ? (!timeSlot.voters.some((voter) => voter.userId === userId) ? Array.from(
          new Set([
            ...timeSlot.voters,
            { name: userDisplayName, userId: userId },
          ]),
        ) : timeSlot.voters)
        : timeSlot.voters.filter((voter) => voter.userId !== userId),
      })),
    };

    console.log('New Poll Data:', newPollData);
    setPollData(newPollData);
    setSelectedTimes(selectedTimes);
  };

  const onSubmit = () => {
    if (hasVoted) {
      setHasVoted(false);
    } else {
      setHasVoted(true);
      if (pollData && receiveProps?.event?._id) {
        // in DB we store accept/decline/maybe as 1/2/0 in the contact's status field
        // If the user has voted but there's no options, we set it to 2 (declined)
        // Otherwise we set it to 1 (accepted)
        // At this stages there's two scenarios:
        // 1. User votes for at least one option (i.e. accepts)
        // 2. User votes for no options (i.e. declines)
        const anyVoteChoicesForUser = pollData.checkedTimes.some((timeSlot) =>
          timeSlot.voters.some((voter) => voter.userId === userId),
        );
        const voteStatus = anyVoteChoicesForUser ? 1 : 2;

        // find the user in the contacts list and update their status via phone number
        const updatedContacts: IContact[] =
          receiveProps?.event?.contacts?.map((contact) => {
            if (contact.phoneNumber === userPhoneNumber) {
              return {
                ...contact,
                status: voteStatus,
              };
            }
            return contact;
          }) ?? [];

        // update the event with the new contacts list
       const {_id, userId: userID, ...restOfTheData } = receiveProps?.event
        const data = {
          ...restOfTheData,
          id: _id,
          contacts: updatedContacts,
          pollData: pollData,
          updateEventMetadata: {
            userId: userId,
            phoneNumber: userPhoneNumber,
            name: userDisplayName,
            wasUpdatedFromPollVote: true,
          },
        };
        updateEvent(data)
          .then(() => {
            receiveProps?.refetch && receiveProps?.refetch()
          })
          .catch((error: unknown) => {
            console.error('Error updating poll data', error);
          });

        const notificationResponseValue =selectedTimes.length? selectedTimes.some((time) => time === 'Not available on all dates') ? 'No' : 'Yes' : 'No';
        const notificationData = {
          type: NotificationType.POLL_VOTE,
          eventId: receiveProps?.event?._id,
          responder: user?._id,
          response: notificationResponseValue,
        }
        const stopNotificationData = {
          notificationType: NotificationType.REMINDER_TO_RESPOND_TO_POLL,
          eventId: receiveProps?.event?._id,
          responderId:user?.id
        }
        sendNotification(notificationData)
        stopNotification(stopNotificationData)
        receiveProps?.refetch && receiveProps?.refetch()
      }
      setIsDrawerOpen(true);
    }
  };

  const onRespond = (response: ReceiveMeetResponseType): Promise<void> => {
    return new Promise((res) => {
      if (response === ReceiveMeetResponseType.ACCEPTED) {
        setState(ReceiveMeetResponseState.ACCEPTED);
        respondEvent({ eventId: receiveProps?.event?._id ?? '', response: 1,
          phoneNumber: user?.phoneNumber ?? '',
        });
        respondEvent({ eventId: receiveProps?.event?._id ?? '', response: 1,
          phoneNumber: user?.phoneNumber ?? '',
        });
      }

      if (response === ReceiveMeetResponseType.DECLINED) {
        setState(ReceiveMeetResponseState.DECLINED);
        respondEvent({ eventId: receiveProps?.event?._id ?? '', response: 2, phoneNumber: user?.phoneNumber ?? '', });
        respondEvent({ eventId: receiveProps?.event?._id ?? '', response: 2, phoneNumber: user?.phoneNumber ?? '', });
      }

      if (response === ReceiveMeetResponseType.MAYBE) {
        setState(ReceiveMeetResponseState.MAYBE);
        respondEvent({ eventId: receiveProps?.event?._id ?? '', response: 3, phoneNumber: user?.phoneNumber ?? '', });
      }

      res();
      receiveProps?.refetch && receiveProps?.refetch()
    });
  };

  const checkEnded = () => {
    receiveProps?.event?.end &&
      dayjs.tz(receiveProps.event.end).isBefore(dayjs.tz(new Date())) &&
      setState(ReceiveMeetResponseState.EXPIRED);
  };
  const checkHasResponded = () => {
    const userIdToCheck = user?.id;
    const eventPollDataTimes = receiveProps?.event?.pollData?.checkedTimes;
    let userHasVoted = false;
    if (!userIdToCheck || !eventPollDataTimes) return;
    eventPollDataTimes.forEach((timeSlot) => {
      timeSlot.voters?.forEach((voter) => {
        if (voter.userId === userIdToCheck) {
          userHasVoted = true;
        }
      });
    });
    if(userHasVoted) {
      setHasVoted(true);
    }
  }

  useEffect(() => {
    checkHasResponded();
  },[receiveProps]);
  
  useEffect(() => {
    checkEnded();

    const endCheck = setInterval(() => {
      checkEnded();
    }, 1000 * 60);

    return () => {
      clearInterval(endCheck);
    };
  }, [receiveProps]);

  // TODO: fix the drawer floating issue properly when address bar is hidden in iOS devices
  useEffect(() => {
    document.body.classList.add('drawer-after');  
    return () => {
      document.body.classList.remove('drawer-after');
    };
  }, []);

  useEffect(() => {
    // Find the matching user in receiveProps.event.contacts based on userId
    const matchedUser = receiveProps?.event?.contacts?.find(
      (eventUser) => eventUser?.userId === user?.id
    );

    if(matchedUser?.status === 2 || matchedUser?.status === 1) {
      setHasVoted(true)
    }
  }, [receiveProps]);

  return (
    <>
      <div className="bg-header rounded-b-md w-full sticky top-0 z-10">
        <ReceiveNavigationBar event={receiveProps?.event} />
        <ReceiveHeader
          expired={state === ReceiveMeetResponseState.EXPIRED}
          by={{
            name: `${receiveProps?.event?.organizer.split(' ')[0]}`,
            id: `${receiveProps?.event?.userId}`,
            photoURL: `${receiveProps?.event?.organizerPhoto ?? ''}`,
          }}
          emoji={receiveProps?.event?.emoji ?? '📖'}
          contacts={receiveProps!.event?.contacts as any}
          type="poll"
          event={receiveProps?.event}
        />
      </div>

      <div className="px-8 ">
        <EventTitle
          title={`${receiveProps?.event?.activity}`}
          by={`${receiveProps?.event?.organizer.split(' ')[0]}`}
          type={EventType.POLL}
        />
        <Separator className="bg-white/20 mb-6" />
        <MeetInfo
          withPadding
          type="now-no-time"
          end={new Date()}
          location={`${receiveProps?.event?.location}`}
          details={`${receiveProps?.event?.details}`}
        />
        
        <InviteList
          title="Who's responded"
          list={inviteeContacts}
          eventId={receiveProps?.event?._id}
          excludeOrganizer
        />

        <CommentList
          event={receiveProps}
          refetchEvent={receiveProps.refetch}
        />

        <Separator className="bg-white/20 my-3" />

        {state === ReceiveMeetResponseState.EXPIRED ? (
            <EndedResponseView
              onSubmit={() =>navigate('/lets-meet')}
            />
        ): hasVoted ? (
          <PollResults
            pollData={pollData}
            totalUsers={inviteeContacts.length || 1} // Pass the total number of users for percentage calculation
          />
        ): (
          pollData && (
            <PollTimeSelector
              pollData={pollData}
              userId={userId ?? ''}
              onTimeSelectionChange={handleTimeSelectionChange}
            />
          )
        )}
      </div>

      <MainButton
        type="primary"
        onClick={onSubmit}
        className={`mx-8 box-border w-auto mt-auto ${isDrawerOpen ? "mb-20" : "mb-16"}`}
        icon={<ChevronRight color="#fff" />}
        loading={false}
      >
        {hasVoted ? 'Edit Vote' : 'Send Vote'}
      </MainButton>

      {hasVoted&&(isDrawerComponentVisible?
      <Drawer.Root modal={false} onClose={()=>setIsDrawerComponentVisible(false)} open={isDrawerComponentVisible} repositionInputs={false}>  
        <Drawer.Overlay className="fixed inset-0 bg-[#1F2339] touch-none hidden" />
        <Drawer.Portal>
          <Drawer.Content
            className="flex flex-col rounded-t-[10px] mt-24 h-fit fixed bottom-0 left-0 right-0 outline-none pb-8 drawer-content transform translate-y-0">
            <MeetResponseAfter
              type={EventType.POLL}
              onRespondChange={() => {
                setHasVoted(false);
                setIsDrawerComponentVisible(false);
                window.scrollTo(0, document?.body?.scrollHeight??0);
              }}
            />
            <div className='w-full h-52 bg-[#1F2339] transform translate-y-52 fixed bottom-0'></div>  
          </Drawer.Content>
        </Drawer.Portal>
      </Drawer.Root>
      :
      <DraggableContainer onDragStart={()=>{setIsDrawerComponentVisible(true)}} longTouchThreshold={10}>
        <div className="flex flex-col bg-[#1F2339] px-7 pt-3 pb-4 fixed bottom-0 w-full" onClick={()=>setIsDrawerComponentVisible(true)}>
          <div className={'flex justify-center mt-4'} >
            <div className="w-28 h-2 rounded-full bg-purple-800"></div>
          </div>
        </div>
      </DraggableContainer>
    )}
    </>
  );
};

export default ReceiveMeetNow;
