import { Separator } from 'components/ui/separator';
import { format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import CommentList from 'modules/letsMeet/components/comments/commentList';
import InviteList from 'modules/letsMeet/components/inviteList/inviteList';
import { MeetInfo } from 'modules/letsMeet/components/meetInfo/meetInfo';
import { EventTitle } from 'modules/letsMeet/components/receive/eventTitle';
import ReceiveHeader from 'modules/letsMeet/components/receive/receiveHeader';
import {
  EventType,
  ReceiveMeetResponseType,
  ReceivePublicMeetResponseState
} from 'modules/letsMeet/types/types';
import { useEffect, useState } from 'react';
import { cn } from 'utils/helpers';

import dayjs from 'dayjs';
import PublicResponseDrawer from 'modules/letsMeet/components/meetResponse/publicResponseDrawer';
import { IEvent } from 'types/event';
import useLocalStorage from 'hooks/useLocalStorage';
import useSessionStorage from 'hooks/useSessionStorage';


// #endregion
interface ReceiveMeetLaterProps {
  event: IEvent | null;
  refetch?: () => void;
}

const ReceivePublicMeetNow = (receiveProps: ReceiveMeetLaterProps) => {

  const [state, setState] = useState(ReceivePublicMeetResponseState.HIDDEN);
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [response, setResponse] = useState<ReceiveMeetResponseType | undefined>(undefined);  
  const [viewState, setViewState] = useState<any>('initial');

  // state values take from local storage initially if they exist
  const [name, setName] = useSessionStorage('cal-user-name', '');
  const [phoneNumber, setPhoneNumber] = useSessionStorage('cal-user-phone-number', '');
  const [countryCode, setCountryCode] = useSessionStorage('cal-user-country-code', '+1');
  const [hasRespondedBefore, setHasRespondedBefore] = useState<boolean>(false);
  const [eventResponses, setEventResponses] = useLocalStorage('cal-user-event-responses', {});
  const [userAccountAvailable, setUserAccountAvailable] = useLocalStorage('cal-user-account-available', false);
  const [currentView, setCurrentView] = useState<'complete'|'otp-input'|'phone-input'|'rsvp-view'|null>(null);

  const showInviteList =
    true||
    state === ReceivePublicMeetResponseState.HIDDEN ||
    state === ReceivePublicMeetResponseState.NOT_RESPONDED;

  const formatDateToLocalTimezone = (
    dateString: string,
    timezone: string,
    formatStr: string,
  ) => {
    // Convert the UTC date string to a Date object
    const date = new Date(dateString);
    // Convert the date to the desired timezone
    const zonedDate = toZonedTime(date, timezone);
    // Format the date to get only the date part in YYYY-MM-DD format
    const formattedDate = format(zonedDate, formatStr);
    return formattedDate;
  };

  const getUserTimezone = () => {
    // eslint-disable-next-line new-cap
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  };

  const formatDateToLocalTimezoneOnly = (
    dateString: string,
    timezone: string,
) => {
  // Convert the UTC date string to a Date object
  const date = new Date(dateString);
  // Convert the date to the desired timezone
  const zonedDate = toZonedTime(date, timezone);
  return zonedDate;
};

  const endDateStr = receiveProps.event?.end as unknown as string ?? new Date().toDateString();
  const formattedEnd = formatDateToLocalTimezoneOnly(endDateStr, getUserTimezone());
  const eventEndTime = dayjs.tz(formattedEnd);

  const checkEnded = () => {
    receiveProps.event?.end &&
      eventEndTime.isBefore(dayjs.tz(new Date())) &&
      setState(ReceivePublicMeetResponseState.ENDED);
  };

  useEffect(() => {
    checkEnded();

    const endCheck = setInterval(() => {
      checkEnded();
    }, 1000 * 60);

    return () => {
      clearInterval(endCheck);
    };
  }, [receiveProps]);

  // find if the user has responded to this event before
  useEffect(() => {    
    const rawResponses = localStorage.getItem('cal-user-event-responses');
    const previousResponse = (rawResponses ? JSON.parse(rawResponses) : {})[receiveProps.event?._id ?? ''];
    if (previousResponse) {
      setViewState('secondary');
      previousResponse===1? setResponse(ReceiveMeetResponseType.ACCEPTED): previousResponse===2? setResponse(ReceiveMeetResponseType.DECLINED): previousResponse ===3? setResponse(ReceiveMeetResponseType.MAYBE) : undefined;      
      setHasRespondedBefore(true);
    }

    // clear session storage when unmount
    return ()=>{
      setName('');
      setPhoneNumber('');
      setCountryCode('+1');
    }
    
    // clear session storage when unmount
    return ()=>{
      setName('');
      setPhoneNumber('');
      setCountryCode('+1');
    }
  }, []);

  const showData = currentView !== 'otp-input' && ((state !== ReceivePublicMeetResponseState.NOT_RESPONDED && state!==ReceivePublicMeetResponseState.HIDDEN && !hasRespondedBefore) || (hasRespondedBefore));

  return (
    <>
      <div className="bg-header rounded-b-md pt-4">
        <ReceiveHeader
          by={{
            name: `${receiveProps.event?.organizer.split(' ')[0] ?? ''}`,
            id: `${receiveProps.event?.userId}`,
            photoURL: `${receiveProps.event?.organizerPhoto ?? ''}`,
          }}
          emoji={receiveProps.event?.emoji ?? '📖'}
          contacts={receiveProps.event?.contacts as any} // TODO: Come back to fix this 
          type="now"
          end={formatDateToLocalTimezone(
            endDateStr,
            getUserTimezone(),
            'h:mma',
          )}
          event={receiveProps.event}
        />
      </div>

      <div className="px-8">
        <EventTitle
          title={receiveProps.event?.activity ?? ''}
          by={receiveProps.event?.organizer.split(' ')[0] ?? ''}
          type={EventType.MEET_NOW}
        />
        <Separator className="bg-white/20" />

        <MeetInfo
          withPadding
          type="now-no-time"
          end={
            receiveProps.event?.end
          }
          location={receiveProps.event?.location ?? ''}
          details={receiveProps.event?.details}
        />

        {showInviteList && (
          <>
            <div
              className={cn(
                'my-6',
                !showData && 'blur-sm',
              )}
            >
              <InviteList
                title="Who’s going"
                list={receiveProps.event?.contacts?.filter((contact:any) => contact?.status !== 2 ).map((contact:any) => contact.name)??[]}
                eventId={receiveProps.event?._id}
                includeOnlyGoing={true}
              />
            </div>
          </>
        )}

        {showData && (
          <CommentList event={receiveProps} refetchEvent={receiveProps.refetch}/>
        )}
      </div>

      <div className="mt-auto">
      <PublicResponseDrawer
          state={state}
          setState={setState}
          public
          eventId={receiveProps.event?._id??''}
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          event={receiveProps.event}
          response={response}
          setResponse={setResponse}
          name={name}
          setName={setName}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          countryCode={countryCode}
          setCountryCode={setCountryCode}
          hasRespondedBefore={hasRespondedBefore}
          setHasRespondedBefore={setHasRespondedBefore}       
          setViewState={setViewState}
          viewState={viewState}          
          currentView={currentView}
          setCurrentView={setCurrentView}     
        />
      </div>

    </>
  );
};

export default ReceivePublicMeetNow;
