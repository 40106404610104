import EventIcon from 'components/eventIcon/EventIcon';
import { Button } from 'components/ui/button';
import { ReactComponent as ArrowRight } from 'assets/images/activity/arrow-right.svg';
import { ReactComponent as BackIcon } from 'assets/images/common/back.svg';
import { cn } from 'utils/helpers';
import { Drawer } from 'vaul';
import { useEffect, useRef, useState } from 'react';
import { XIcon } from 'lucide-react';
import { DraggableContainer } from 'components/draggableContainer';

type MeetResponseAfterPublic = {
  emoji: string;
  title: string;
  isPublic: true;
  hideButton?: boolean;
  onRespondChange: () => void;
  isPopup?: boolean;
  updateActionText?: string;
  setBottomSheet?: any;
  bottomSheet?: boolean;
};
type MeetResponseAfterPrivate = {
  emoji: string;
  title: string;
  onRespondChange: () => void;
  isPublic?: false;
  hideButton?: boolean;
  setBottomSheet?: any;
  bottomSheet?: boolean;
};

type MeetResponseAfterProps = MeetResponseAfterPublic | MeetResponseAfterPrivate;

const MeetResponseAfterBody: React.FC<MeetResponseAfterProps> = (props) => {
  const [snap, setSnap] = useState<number | string | null>('380px');
  const [open, setOpen] = useState(true);
  const [isDrawerComponentVisible, setIsDrawerComponentVisible] = useState(true);

  const drawer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (drawer.current) {
      drawer.current.removeAttribute('tabindex');
    }
  }, [open, snap, drawer]);

  // TODO: fix the drawer floating issue properly when address bar is hidden in iOS devices
  useEffect(() => {
    document.body.classList.add('drawer-after');  
    return () => {
      document.body.classList.remove('drawer-after');
    };
  }, []);

  return (
    isDrawerComponentVisible?
    <Drawer.Root modal={false} onClose={()=>setIsDrawerComponentVisible(false)} open={isDrawerComponentVisible} repositionInputs={false}>  
      <Drawer.Overlay className="fixed inset-0 bg-[#1F2339] touch-none hidden" />
      <Drawer.Portal>
        <Drawer.Content
          className="flex flex-col rounded-t-[10px] mt-24 h-fit fixed bottom-0 left-0 right-0 outline-none drawer-content transform translate-y-0"
          autoFocus={false}
          onOpenAutoFocus={(e) => e.preventDefault()}
          ref={drawer}
        >
          <div
            className={cn(
              'flex flex-col items-center pt-9 pb-16 bg-[#1F2339] rounded-t-xl px-2',
              props.isPublic && 'pb-3 pt-3',
            )}
          >
            <div
              className="w-28 h-2 mb-10 rounded-full bg-purple-800"
              onClick={() =>
                props?.setBottomSheet
                  ? props.setBottomSheet(!props.bottomSheet)
                  : null
              }
            ></div>
            <EventIcon icon={props.emoji} background="#ebdfff" size="medium" />
            <div
              className={cn(
                'font-serif text-2xl text-center tracking-tighter text-white mt-2',
                props.isPublic &&
                  props.isPopup &&
                  'font-sans tracking-normal text-xl font-semibold',
              )}
            >
              {props.title}
            </div>
            {!props.hideButton && (
              <Button
                variant="link"
                className="text-link hover:text-link font-medium !no-underline flex items-center gap-2 py-0"
                onClick={props.onRespondChange}
              >
                {props.isPublic && <BackIcon />}
                {props.isPublic
                  ? (props.updateActionText ?? 'Update my response')
                  : 'Update my response'}{' '}
                {!props.isPublic && <ArrowRight className="h-3 w-3" />}
              </Button>
            )}
          </div>
          <div className='w-full h-52 bg-[#1F2339] transform translate-y-52 fixed bottom-0'></div>  
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
    :
    <DraggableContainer onDragStart={()=>{setIsDrawerComponentVisible(true)}} longTouchThreshold={10}>
      <div className="flex flex-col bg-[#1F2339] px-7 pt-3 pb-4 fixed bottom-0 w-full" onClick={()=>setIsDrawerComponentVisible(true)}>
        <div className={'flex justify-center mt-4'} >
          <div className="w-28 h-2 rounded-full bg-purple-800"></div>
        </div>
      </div>
    </DraggableContainer>
  );
};

export default MeetResponseAfterBody;
