type LayoutProps = {
  children: React.ReactNode;
};

const ReceiveLayout: React.FC<LayoutProps> = (props) => {
  return (
    <div className="flex items-center justify-center text-white bg-main min-h-screen relative">
      <div className={'flex flex-col  min-h-screen w-full max-w-md overflow-y-auto'}>
        <div className="flex flex-col min-h-screen flex-1 w-full">
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default ReceiveLayout;
